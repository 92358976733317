import * as dompack from 'dompack';
import { WWBasicSite } from '@mod-ww/webdesigns/template/ww';

import '@mod-mso/webdesigns/mso/widgets/ctaslider/ctaslider';
import '@mod-ww/widgets/photoalbum/photoalbum';

import './adoseofrose.scss';
import CircleType from 'circletype';

dompack.onDomReady(() => {
  new WWBasicSite({});

  // Instantiate `CircleType` with an HTML element.
  dompack.qSA('.curve-text__text').forEach(el => {
    const circleType = new CircleType(el);
    circleType.radius(1900);
    el.style.opacity = '1';
  });
});
