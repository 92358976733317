/* global $ */
import './ctaslider.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  const sliders = dompack.qSA('.w-ctaslider');

  for (const slider of sliders) {
    const actions = slider.querySelectorAll('.w-ctaslider__action');

    actions.forEach((a) =>
      a.addEventListener('click', (e) => onActionClick(e, slider))
    );
  }
});

function onActionClick(e, slider) {
  e.preventDefault();

  const availableWidth = dompack.qS('.ww-grid').offsetWidth;
  const colWidth = slider.querySelector('.w-ctaslider__col').offsetWidth;
  const fitCount = Math.floor(availableWidth / colWidth);

  // console.log(availableWidth, colWidth, fitCount);

  const shiftBy = parseInt(e.target.dataset.shiftAmount);
  const currentShift = parseInt(
    getComputedStyle(slider).getPropertyValue('--shift-count')
  );
  const max = slider.querySelectorAll('.w-ctaslider__col').length - fitCount;

  if (max <= 0) return;

  if (currentShift >= max && shiftBy > 0) {
    slider.style.setProperty('--shift-count', 0);
  } else if (currentShift === 0 && shiftBy < 0) {
    slider.style.setProperty('--shift-count', max);
  } else {
    slider.style.setProperty('--shift-count', shiftBy + currentShift);
  }
}
